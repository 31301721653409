import * as React from "react"
import { graphql } from 'gatsby';

// Plugins
import { useTranslation } from 'gatsby-plugin-react-i18next';

// Application
import Layout from '../shell/layout'

// Styles
import { contactPage, gmapsContainer } from '../styles/contact.module.css'

// Markup
const ContactPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Layout pageTitle={`${t("contact")} | `} pagePath="contact">
        <div className={contactPage}>
          {/* Contact info */}
          <section>
            <h2>{t("contact-distributed-exclusively-by")}</h2>
            <h1>Cycles Marinoni Inc.</h1>
            <p>{t("contact-address-street")}</p>
            <p>Terrebonne, QC, J6W 4L2</p>
            <h2>{t("phone")}</h2>
            <p>450.471.7133</p>
            <h2>{t("email")}</h2>
            <p mailto="info@marinonicycles.com">info@marinonicycles.com</p>
          </section>

          {/* Google Maps Embed */}
          <section>
            <div className={gmapsContainer}>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2785.9615931526564!2d-73.62701308422703!3d45.71180732490781!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc8de7141e7e8e5%3A0xd6046fb877f95216!2sCycles%20Marinoni%20Inc!5e0!3m2!1sen!2sca!4v1604339516692!5m2!1sen!2sca" width="100%" height="100%" style={{ border: 0 }} aria-hidden="false" title="Cycles Marinoni Inc. location"></iframe>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}

export default ContactPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;